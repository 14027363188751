<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <v-card>
      <v-card-title>Skift kodeord</v-card-title>
      <v-card-text>
        <v-window :value="passwordChanged" vertical>
          <v-window-item :value="false">
            <p v-if="!adminChangePassword">Indtast dit nuværende og dit ønskede kodeord</p>
            <v-form ref="changePasswordForm" v-model="isValid">
              <v-row no-gutters>
                <v-col cols="12" v-if="!adminChangePassword" class="pt-2">
                  <v-text-field
                    outlined
                    v-model="currentPassword"
                    label="Nuværende kodeord"
                    :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.length]"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    @click:append="showCurrentPassword = !showCurrentPassword"
                  />
                </v-col>
                <v-col cols="12" class="pt-2">
                  <v-text-field
                    outlined
                    v-model="newPassword"
                    label="Nyt kodeord"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.length, notMatching]"
                    :type="showNewPassword ? 'text' : 'password'"
                    @click:append="showNewPassword = !showNewPassword"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-alert type="error" v-if="errorMessage">
                    {{ errorMessage }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-window-item>
          <v-window-item :value="true">
            <div>
              <p>Kodeordet er nu opdateret</p>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <r-btn class="mr-4 mb-4" :block="$vuetify.breakpoint.mobile" @click="show = false">Luk</r-btn>
        <r-btn class="mr-4 mb-4" creation :block="$vuetify.breakpoint.mobile" :disabled="!isValid" @click="saveChangedPassword" :loading="isLoading">
          Skift kodeord
        </r-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import apiService from '@/services/apiService.js';

export default {
  name: 'changePassword',
  data: () => ({
    username: '',
    adminChangePassword: false,
    show: false,
    currentPassword: '',
    newPassword: '',
    isValid: false,
    isLoading: false,
    showCurrentPassword: false,
    showNewPassword: false,
    errorMessage: null,
    passwordChanged: false,
    rules: {
      required: (value) => !!value || 'Password kan ikke være tomt',
      length: (value) => value?.length > 5 || 'Kodeord skal være mindst 6 tegn langt.',
    },
  }),
  methods: {
    notMatching(value) {
      return value !== this.currentPassword || 'Password kan ikke matche';
    },
    changePassword(user, adminChanging) {
      this.username = user.email;
      this.show = true;
      this.newPassword = '';
      this.passwordChanged = false;
      this.showCurrentPassword = false;
      this.showNewPassword = false;
      if (adminChanging == true) this.adminChangePassword = true;
    },
    async saveChangedPassword() {
      if (this.$refs.changePasswordForm.validate()) {
        this.isLoading = true;
        if (this.adminChangePassword == true) {
          var user = {
            username: this.username,
            password: this.newPassword,
          };
          await apiService
            .adminResetPassword(user)
            .then(() => {
              this.passwordChanged = true;
            })
            .catch((e) => {
              this.errorMessage = e.message;
            })
            .finally(() => {
              this.isLoading = false;
              this.show = false;
            });

          this.isLoading = false;
          this.show = false;
        } else {
          await apiService
            .changePassword({ CurrentPassword: this.currentPassword, NewPassword: this.newPassword })
            .then(() => {
              this.passwordChanged = true;
            })
            .catch((e) => {
              this.errorMessage = e.message;
            })
            .finally(() => {
              this.isLoading = false;
              this.show = false;
            });
        }
      }
    },
  },
};
</script>
