import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const TITLE_PREFIX = 'Køreskoleguide';
const DEFAULT_TITLE = 'Management';

const routes = [
  {
    path: '/',
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: '',
        name: 'FrontPage',
        component: () => import('@/views/FrontPage.vue'),
      },
      {
        path: 'org',
        name: 'org',
        meta: {
          title: 'Køreskoler',
        },
        component: () => import('@/components/admin/OrganizationList.vue'),
      },
      {
        path: 'org/:OrgId',
        name: 'orgDetails',
        props: true,
        component: () => import('@/components/details/OrganizationDetails.vue'),
      },
      {
        path: 'students',
        name: 'students',
        meta: {
          title: 'elever',
        },
        props: true,
        component: () => import('@/components/admin/StudentList.vue'),
      },
      {
        path: 'students/:UserId',
        name: 'StudentDetails',
        props: true,
        component: () => import('@/components/details/StudentDetails.vue'),
      },
      {
        path: 'staff',
        name: 'staff',
        meta: {
          title: 'kørelærere',
        },
        props: true,
        component: () => import('@/components/admin/StaffList.vue'),
      },
      {
        path: 'stats',
        name: 'Statistics',
        meta: {
          title: 'Statistik',
        },
        props: true,
        component: () => import('@/components/admin/Statistics.vue'),
      },
      {
        path: 'staff/:UserId',
        name: 'StaffDetails',
        props: (route) => ({ ...route.params }),
        component: () => import('@/components/details/StaffDetails.vue'),
      },
      // {
      //   path: 'theoryTeam',
      //   name: 'TheoryTeam',
      //   meta: {
      //     title: 'Teori Hold',
      //   },
      //   component: () => import('@/components/admin/TheoryTeamList.vue'),
      // },
      // {
      //   path: 'theoryTeam/:theoryTeamId',
      //   name: 'TheoryTeamDetails',
      //   meta: {
      //     title: 'Teori Hold detaljer',
      //   },
      //   props: (route) => ({ ...route.params }),
      //   component: () => import('@/components/details/TheoryTeamDetails.vue'),
      // },
      {
        path: 'calendar/:UserId',
        name: 'Calendar',
        meta: {
          title: 'Kalender',
        },
        props: true,
        component: () => import('@/components/calendar/FullCalendar.vue'),
      },
      {
        path: 'events/:StudentId',
        name: 'StudentLessonDetails',
        meta: {
          title: 'Begivenhed',
        },
        props: true,
        component: () => import('@/components/details/StudentLessonDetails.vue'),
      },
      {
        path: 'lessonPlan',
        name: 'ModulePlan',
        meta: {
          title: 'Lektions plan',
        },
        component: () => import('@/components/ModulePlan.vue'),
      },
      {
        path: 'newOrganization',
        name: 'newOrgPage',
        meta: {
          title: 'Opret ny køreskole',
        },
        component: () => import('@/components/NewOrganizationPage.vue'),
      },
      {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue'),
      },
      {
        path: '/activate',
        name: 'Activate',
        component: () => import('../views/Activate.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? `${TITLE_PREFIX} - ${to.meta.title}` : `${TITLE_PREFIX} - ${DEFAULT_TITLE}`;
  });
});

// Tests to prevent redundant routing

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete

        resolve(this.currentRoute);
      },
      (error) => {
        // only ignore NavigationDuplicated error
        if (error.name === 'NavigationDuplicated') {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

router.beforeEach(async (to, from, next) => {
  let success = await store.dispatch('ENSURE_AUTH');
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    success = store.getters.isAdmin;
  } else if (to.matched.some((record) => record.meta.requiresStaff)) {
    success = store.getters.isStaff;
  } else if (!to.matched.some((record) => record.meta.requiresAuth)) {
    success = true;
  }

  // if not authenticated, redirect to login page.
  if (!success) {
    router.push({ name: 'FrontPage' });
  } else {
    next();
  }
});

export default router;
