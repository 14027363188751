<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="4" class="d-flex pr-1 justify-end my-auto">
        <p class="my-auto black--text">Vælg køreskole</p>
      </v-col>
      <v-col cols="8" class="my-auto">
        <v-select
          v-if="failedToFetch.length == 0"
          :disabled="readOnly"
          :items="organizations"
          item-text="name"
          item-value="id"
          v-model="changeOrgId"
          outlined
          hide-details
          dense
        />
        <v-select v-else readonly :items="failedToFetch" item-text="name" item-value="id" v-model="changeOrgId" outlined hide-details dense />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import apiService from '@/services/apiService';

export default {
  name: 'OrganizationSelecter',
  props: {
    readOnly: Boolean,
  },
  watch: {
    user: {
      // This is made to prevent issues where some user written data will get saved in cache
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrganizations();
        }
      },
    },
  },
  computed: {
    ...mapGetters(['currentOrgId', 'user']),
    changeOrgId: {
      get() {
        return this.currentOrgId;
      },
      set(val) {
        store.dispatch('SET_ORGANIZATIONID', val);
      },
    },
  },
  data: () => ({
    organizations: [],
    failedToFetch: [],
  }),
  methods: {
    async getOrganizations() {
      if (this.user) {
        this.organizations = await apiService.getOrganizationList();
        if (this.organizations.length > 0) {
          // if (this.currentOrgId && this.currentOrgId !== 1 && !this.organizations.some((x) => x.id === this.changeOrgId)) {
          //   this.changeOrgId = this.organizations[0].id;
          // }
          this.changeOrgId = this.organizations[0].id;
        }
        if (this.organizations.length == 0) {
          this.failedToFetch = [{ id: '1', name: 'Ingen køreskole tilgængelig' }];
          this.changeOrgId = '1';
          store.dispatch('REMOVE_ORGANIZATIONID'); // We have to set orgId to null to prevent API requests with 1 as id
        }
      }
    },
  },
  created() {
    this.getOrganizations();
  },
};
</script>

<style>
</style>