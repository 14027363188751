import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import util from './plugins/util';
import organizationAccess from './plugins/organizationAccess';
import RBtn from './components/RBtn.vue';
import ConfirmationDialog from './components/dialogs/ConfirmationDialog.vue';

Object.defineProperty(Vue.prototype, '$util', { value: util });
Object.defineProperty(Vue.prototype, '$organizationAccess', { value: organizationAccess });

Vue.config.productionTip = false;

Vue.component('r-btn', RBtn);
Vue.component('confirmation-dialog', ConfirmationDialog);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
