const OrganizationAccess = Object.freeze({
  AddStudentComment: 1,
  ReadStudents: 2,
  ModifyStudents: 4,
  AssignSelfStudent: 8,
  ReadStaff: 16,
  ModifyStaff: 32,
  ReadLessonPlan: 64,
  ModifyLessonPlan: 128,
  ReadSite: 256,
  ModifySite: 512,
  ReadUnassignedStudents: 1024,
  ReadTheoryTeams: 2048,
  ModifyTheoryTeams: 4096,
});

export default OrganizationAccess;
