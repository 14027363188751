<template>
  <v-app>
    <nav-bar v-if="user" @show-login="showLoginDialog" />
    <v-snackbar
      v-if="globalAlert"
      v-model="globalAlert.show"
      class="mt-4 pa-4 mb-6 mr-n4"
      transition="slide-y-reverse-transition"
      bottom
      right
      elevation="0"
      :color="globalAlert.color"
      :timeout="parseInt(globalAlert.timeout)"
    >
      <template v-slot:action="{ attrs }">
        <r-btn icon v-bind="attrs" @click="globalAlert.show = false" title="Dismiss">
          <v-icon>mdi-close-circle-outline</v-icon>
        </r-btn>
      </template>
      <div :class="globalAlert.textColor ? globalAlert.textColor + '--text' : 'black--text'">
        <div class="font-weight-bold">{{ globalAlert.title }}</div>
        <div class="py-2" v-html="globalAlert.message" />
        <div class="py-2" v-if="globalAlert.details">
          <div class="text-pre">{{ globalAlert.details }}</div>
        </div>
      </div>
    </v-snackbar>
    <login-dialog ref="loginDialogRef" />
    <v-main class="background">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginDialog from './components/dialogs/LoginDialog.vue';
import NavBar from './components/NavBar.vue';
export default {
  components: { NavBar, LoginDialog },
  name: 'App',

  data: () => ({}),
  computed: {
    ...mapGetters(['globalAlert', 'darkMode', 'user']),
  },
  watch: {
    darkMode: {
      immediate: true,
      handler(val) {
        this.$vuetify.theme.dark = val;
      },
    },
  },
  methods: {
    showLoginDialog(val) {
      this.$refs.loginDialogRef.showDialog(val);
    },
  },
  mounted() {
    this.$store.dispatch('LOAD_UI_SETTINGS');
    this.$vuetify.theme.dark = this.$store.getters.darkMode;
  },
  created() {},
};
</script>

<style>
@font-face {
  font-display: auto;
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans"),
    url('./assets/MitID-assets/Fonts/IBMPlexSans-Regular.ttf') format("truetype");
    
  font-family: "IBM Plex Sans Bold";
  src: local("IBM Plex Sans Bold"),
    url('./assets/MitID-assets/Fonts/IBMPlexSans-Bold.ttf') format("truetype");

  font-family: "IBM Plex Sans Semi-Bold";
  src: local("IBM Plex Sans Semi-Bold"),
    url('./assets/MitID-assets/Fonts/IBMPlexSans-SemiBold.ttf') format("truetype");
}
</style>