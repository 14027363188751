import axios from 'axios';
import { DateTime } from 'luxon';
import store from '@/store/index';
import router from '@/router';

const service = {
  api: process.env.VUE_APP_API_URL,

  async getOptions(inOptions) {
    if (!inOptions?.noAuth && store.getters.user && DateTime.fromISO(store.getters.user.expires) < DateTime.now()) {
      console.log('token expired, refreshing');

      const user = await this.refreshAuth(store.getters.user.refreshToken);

      store.commit('updateUser', user);
    }

    const token = store.getters.authToken;

    if (!inOptions?.noAuth && token) {
      return {
        ...inOptions,
        headers: {
          Authorization: `Bearer ${token}`,
          ...inOptions?.headers,
        },
      };
    } else {
      return inOptions;
    }
  },
  handleResponseError(err) {
    if (err?.response?.status === 401) {
      // not authenticated, consider user logged out
      store.dispatch('LOGOUT');
      router.push('/');
    } else {
      let message;
      // let details;
      if (err.response?.data.type) {
        if (err.response.data.type === 'https://tools.ietf.org/html/rfc7231#section-6.5.1') {
          // details = '';
          // for (let x in err.response.data.errors) {
          //   details += `${x}: ${err.response.data.errors[x]}\r\n`;
          // }
        }
        message = err.response.data.title;
      } else {
        message = err.message;
        // details = err.response?.data;
      }
      store.commit('alert', {
        show: true,
        title: 'API error',
        color: '#AD2433',
        textColor: 'white',
        message,
        // details,
      });
    }
  },
  async handleResponse(task) {
    try {
      const response = await task;

      if (response.headers['x-pagination']) {
        return {
          paging: JSON.parse(response.headers['x-pagination']),
          data: response.data,
        };
      }

      return response.data;
    } catch (err) {
      this.handleResponseError(err);
      throw err;
    }
  },

  async post(endpoint, payload, options) {
    return this.handleResponse(axios.post(`${this.api}/${endpoint}`, payload, await this.getOptions(options)));
  },
  postJsonString(endpoint, payload, options) {
    return this.post(endpoint, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    });
  },
  async get(endpoint, payload) {
    return this.handleResponse(axios.get(`${this.api}/${endpoint}`, await this.getOptions(payload)));
  },
  async put(endpoint, payload) {
    return this.handleResponse(axios.put(`${this.api}/${endpoint}`, payload, await this.getOptions()));
  },
  async delete(endpoint, payload) {
    return this.handleResponse(axios.delete(`${this.api}/${endpoint}`, await this.getOptions(payload)));
  },

  // Endpoints

  // #region Auth
  login(credentials) {
    return this.post('auth/login', credentials, { noAuth: true });
  },

  refreshAuth(refreshCode) {
    return this.postJsonString('auth/refresh', refreshCode, { noAuth: true });
  },

  changePassword(passwordChanges) {
    return this.post('auth/changePassword', passwordChanges);
  },

  activate(payload) {
    return this.post('auth/activate', payload);
  },

  resetPassword(username) {
    return this.postJsonString('auth/resetPassword', username, {
      noAuth: true,
    });
  },

  resetPasswordToken(options) {
    return this.post('auth/resetPasswordToken', options, { noAuth: true });
  },

  verifyStaffToken(token) {
    return this.postJsonString('staff/verifyToken', token, { noAuth: true });
  },

  activateStaffWithToken(props) {
    return this.post('staff/activateWithToken', props, { noAuth: true });
  },

  adminResetPassword(user) {
    return this.put('auth/adminPasswordReset', user);
  },
  // #endregion Auth

  // #region Getters
  getStudentList(orgId, showAll) {
    return this.get(`student/all/${orgId}`, { params: { showAll: showAll } });
  },

  getStudentById(id) {
    return this.get(`student/${id}`);
  },

  getStaffList() {
    return this.get('staff/allStaff');
  },

  getStaffById(id) {
    return this.get(`staff/${id}`);
  },

  updateStaffOrganizationAccess(staffAccess) {
    return this.put('staffOrganizationAccess', staffAccess);
  },

  // getTheoryTeamList(organizationId) {
  //   return this.get(`theoryTeam/list/${organizationId}`);
  // },

  // getTheoryTeam(theoryTeamId) {
  //   return this.get(`theoryTeam/${theoryTeamId}`);
  // },

  // getTheoryTeamCodes(orgId, theoryTeamId) {
  //   return this.get(`theoryTeam/getTheoryCodes/${orgId}/${theoryTeamId}`);
  // },

  // getGenerateTheoryTeamCode(TheoryTeamId) {
  //   return this.get(`theoryTeam/generateTheoryTeamCode/${TheoryTeamId}`);
  // },

  getStaffListByOrgId(id) {
    return this.get(`staff/all/${id}`);
  },

  getOrganizationList() {
    return this.get('organization');
  },

  getAdminOrganizationList() {
    return this.get('organization/all');
  },

  getCompanyCodes(orgId) {
    return this.get(`organization/${orgId}/codes`);
  },

  getOrganizationById(id) {
    return this.get(`organization/${id}`);
  },

  getEducationPlan(educationPlanType) {
    return this.get(`educationPlan/${educationPlanType}`);
  },

  getModulesByOrg(orgId) {
    return this.get(`module/all/${orgId}`);
  },

  getModulesByModulePlan(modulePlanId, showAll) {
    return this.get(`module/modulePlan/${modulePlanId}?showAll=${showAll ? 'true' : 'false'}`);
  },

  getModulePlanList(organizationId, showAll) {
    return this.get(`moduleList/all/${organizationId}?showAll=${showAll ? 'true' : 'false'}`);
  },

  getLessons() {
    return this.get('event');
  },

  getUserEventsById(id) {
    return this.get(`event/userEvents/${id}`);
  },

  getStudentModuleStatus(id) {
    return this.get(`moduleList/user/completed/${id}`);
  },

  getModuleListOfUser(studentId) {
    return this.get(`modulelist/user/${studentId}`);
  },

  getUserEventNoLessonsById(id) {
    return this.get(`event/userEvents/noLessons/${id}`);
  },

  getEventDetails(id) {
    return this.get(`event/${id}`);
  },

  getStudentLessonsById(id) {
    return this.get(`studentLessons/${id}`);
  },

  getEventListEvents(id) {
    return this.get(`eventList/${id}/events`);
  },

  getStudentEventLogPDF(id) {
    return this.get(`student/${id}/getEventLog`, { responseType: 'blob' });
  },

  checkOrganizationAccess(organizationAccess) {
    return this.get(`staffOrganizationAccess/check/${organizationAccess}`);
  },

  getStaffOrganizationAccess(organizationId, staffId) {
    return this.get(`StaffOrganizationAccess/${organizationId}/${staffId}`);
  },

  generateOrganizationCode(ModuleListId) {
    return this.get(`organization/generateOrganizationCode/${ModuleListId}`);
  },
  getOrgCodes(orgId) {
    return this.get(`organization/getCompanyCodes/${orgId}`);
  },

  getStandardDay() {
    return this.get('staff/standardDay');
  },

  // #endregion Getters

  // #region Setters
  updateStudent(user) {
    return this.put('student', user);
  },

  deactivateStudent(userId) {
    return this.post(`student/deactivate/${userId}`);
  },

  sendStudentEventLog(id) {
    return this.post(`student/${id}/sendEventLog`);
  },

  finishStudent(id) {
    return this.post(`student/finish/${id}`);
  },

  updateStaff(user) {
    return this.put('staff', user);
  },

  // updateTheoryTeam(theoryTeam) {
  //   return this.put('theoryTeam', theoryTeam);
  // },

  updateOrganization(organization) {
    return this.put('organization', organization);
  },

  updateModule(module) {
    return this.put('module', module);
  },

  addModuleToList(module) {
    return this.put('module/addModuleToModuleList', module);
  },

  updateModulePlan(modulePlan) {
    return this.put('moduleList', modulePlan);
  },

  releaseModulePlan(id) {
    return this.post(`moduleList/${id}/release`);
  },

  updateEventList(EventList) {
    return this.put('eventList', EventList);
  },

  updateEvent(lesson) {
    return this.put('event', lesson);
  },

  updateEventTime(event) {
    return this.put('event/UpdateTime', event);
  },

  updateParticipate(participant) {
    return this.put('event/Participate', participant);
  },

  updateStudentLesson(model) {
    return this.put('studentLessons/updatestate', model);
  },

  takeStudent(id) {
    return this.post(`student/${id}/assign`);
  },

  releaseStudent(id) {
    return this.post(`student/${id}/unassign`);
  },

  cancelEvent(id) {
    return this.post(`event/CancelEvent/${id}`);
  },

  removeEvent(id) {
    return this.post(`event/RemoveEvent/${id}`);
  },

  cancelEventList(eventListId) {
    return this.post(`eventList/CancelEventList/${eventListId}`);
  },

  removeEventList(eventListId) {
    return this.post(`eventList/RemoveEventList/${eventListId}`);
  },

  addExistingStaffToOrganization(staffOrganizationAccess) {
    return this.put('staffOrganizationAccess/add', staffOrganizationAccess);
  },

  addDrivingTest(studentId, eventObj) {
    return this.post(`event/DrivingTest/${studentId}`, eventObj);
  },

  addExtraLesson(studentId, eventObj) {
    return this.post(`event/ExtraLesson/${studentId}`, eventObj);
  },

  updateEventsByEventListId(model, id) {
    return this.put(`event/UpdateEventByEventListId/${id}`, model);
  },

  createNewOrgWithUser(model) {
    return this.put(`organization/CreateNewOrg`, model);
  },

  saveStandardDay(eventTemplates) {
    return this.put('staff/saveStandardDay', eventTemplates);
  },

  getNameByMitId(token) {
    return this.postJsonString('auth/mitid', token);
  },

  getOrganizationReport() {
    return this.get('report');
  },

  //#region Notifications

  sendCustomNotification(organizationId, request) {
    return this.post(`notification/send/${organizationId}`, request);
  },

  //#endregion Notifications

  // #endregion Setters

  // #region Delete
  deleteModulePlan(id) {
    return this.delete(`moduleList/${id}`);
  },

  deleteModule(id) {
    return this.delete(`module/${id}`);
  },

  deleteOrganization(id) {
    return this.delete(`organization/${id}`);
  },

  deactivateStaff(id) {
    return this.post(`staff/deactivate/${id}`);
  },

  // deleteTheoryTeam(id) {
  //   return this.delete(`theoryTeam/${id}`);
  // },

  // #endregion Delete
};

export default service;
