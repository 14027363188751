<template>
  <div>
    <v-list>
      <v-list-item-title v-if="user"> Logget ind som {{ user.name }}</v-list-item-title>
      <v-list-item v-if="!user">
        <r-btn plain text block @click="changeLoginDialogState(true)">Log ind</r-btn>
      </v-list-item>
      <v-list-item v-else>
        <r-btn plain text block @click="logOut">Log ud</r-btn>
      </v-list-item>
      <v-list-item v-if="user">
        <r-btn plain text block @click="$emit('show-changePassword')">Skift kodeord</r-btn>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'UserMenu',
  data: () => ({
    showLoginDialog: false,
  }),
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    changeLoginDialogState(val) {
      this.$emit('show-login', val);
    },
    logOut() {
      this.$store.dispatch('LOGOUT').then(() => {
        this.$store.dispatch('REMOVE_ORGANIZATIONID').then(() => {
          this.$router.push({ name: 'FrontPage' });
        });
      });
    },
  },
};
</script>

<style>
</style>