import apiService from '@/services/apiService';
import util from '@/plugins/util';

export default {
  state: {
    user: null,
    currentOrgId: null,
    currentOrgAccess: [],
  },
  getters: {
    user: (state) => state.user,
    authToken: (state) => state.user?.token,
    isAuthenticated: (state) => !!state.user,
    isAdmin: (state) => state.user?.roles.includes('admin'),
    isStaff: (state) => state.user?.roles.includes('staff') || state.user?.roles.includes('admin'),
    hasRole: (state) => (role) => state.user?.roles.includes(role),
    currentOrgId: (state) => (state.currentOrgId ? state.currentOrgId : localStorage.getItem('currentOrgId')),
    hasAccess: (state) => (access) => state.currentOrgAccess.includes(access),
  },
  actions: {
    async AUTHENTICATE({ commit }, credentials) {
      const user = await apiService.login(credentials);

      commit('updateUser', user);
      localStorage.setItem('refreshToken', user.refreshToken);

      return user;
    },
    async AUTHENTICATE_REFRESH({ commit }) {
      const cachedToken = localStorage.getItem('refreshToken');

      if (cachedToken) {
        try {
          const user = await apiService.refreshAuth(cachedToken);

          commit('updateUser', user);
          localStorage.setItem('refreshToken', user.refreshToken);

          return true;
        } catch (err) {
          if (err?.response?.status === 401) {
            console.log('stored refresh token not accepted, clearing it');
            localStorage.removeItem('refreshToken');
          } else {
            console.log('unable to utilize stored refresh token');
          }
          return false;
        }
      }

      return false;
    },
    async ENSURE_AUTH({ getters, dispatch }) {
      if (!getters.isAuthenticated) {
        await dispatch('AUTHENTICATE_REFRESH');
      }
      return getters.isAuthenticated;
    },
    async RESET_PASSWORD(store, username) {
      return await apiService.resetPassword(username);
    },
    async LOGOUT({ commit }) {
      localStorage.removeItem('refreshToken');
      commit('updateUser', null);
    },
    async SET_ORGANIZATIONID({ commit, state }, orgId) {
      commit('setOrgId', orgId);
      localStorage.setItem('currentOrgId', orgId);

      if (state.user) {
        await apiService.getStaffOrganizationAccess(state.currentOrgId, state.user.id).then((result) => {
          commit('setOrgAccess', util.parseStaffAccessValue(result.organizationAccess, true));
        });
      }
    },
    REMOVE_ORGANIZATIONID({ commit }) {
      commit('removeOrgId');
      localStorage.removeItem('currentOrgId');
    },
  },
  mutations: {
    updateUser: (state, user) => {
      state.user = user;
    },
    updateUserSetting: (state, { setting, value }) => {
      state.user.settings[setting] = value;
    },
    setOrgId: (state, orgId) => {
      state.currentOrgId = orgId;
    },
    removeOrgId: (state) => {
      state.currentOrgId = null;
      state.currentOrgAccess = [];
    },
    setOrgAccess: (state, access) => {
      state.currentOrgAccess = access;
    }
  },
};
