<template>
  <v-app-bar class="navbar" app>
    <v-row no-gutters>
      <v-col cols="2" class="d-flex flex-row align-center">
        <v-toolbar-title class="mx-auto">Køreskoleguide</v-toolbar-title>
      </v-col>
      <v-col cols="9" class="my-auto">
        <v-tabs fixed-tabs center-active color="activeButton" background-color="navbar">
          <v-tab v-if="user" :to="{ name: 'Calendar', params: { UserId: user.id } }">Kalender</v-tab>
          <v-tab v-if="isStaff && hasAccess('ReadStudents')" :to="{ name: 'students' }">Elever</v-tab>
          <v-tab v-if="isAdmin" :to="{ name: 'staff' }">Kørelærere</v-tab>
          <v-tab v-if="isAdmin" :to="{ name: 'Statistics' }">Statistik</v-tab>
          <!-- <v-tab v-if="hasAccess('ReadTheoryTeams')" :to="{ name: 'TheoryTeam' }">Teorihold</v-tab> -->
          <v-tab v-if="isAdmin" :to="{ name: 'org' }">Køreskoler</v-tab>

          <v-tab
            v-if="hasAccess('ReadSite') && currentOrgId != null"
            :to="{ name: `orgDetails`, params: { OrgId: currentOrgId } }"
            >Køreskole</v-tab
          >
          <v-tab v-if="user" :to="{ name: 'ModulePlan' }">Lektionsplaner</v-tab>
        </v-tabs>
      </v-col>

      <v-col cols="0" v-if="user" class="d-flex my-auto ml-auto">
        <v-row no-gutters>
          <v-col cols="" class="my-auto ml-auto">
            <organization-selector v-show="false" />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="1" align="right" sm="1" class="my-auto ml-auto">
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <r-btn depressed large :close-on-content-click="false" title="Brugerinfo" icon v-bind="attrs" v-on="on" :color="user ? 'activeButton' : 'button'">
              <v-icon v-if="!$vuetify.breakpoint.mobile">mdi-account-circle</v-icon>
              <v-icon v-else>mdi-menu</v-icon>
            </r-btn>
          </template>
          <v-card>
            <v-card-text>
              <UserMenu @show-login="bubbleEmit" @show-changePassword="$refs.ChangePasswordRef.changePassword(false)" />
              <user-settings />
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <change-password-dialog ref="ChangePasswordRef" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenu from './UserMenu.vue';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import UserSettings from './UserSettings.vue';
import OrganizationSelector from './OrganizationSelector.vue';

export default {
  name: 'NavBar',
  components: {
    UserMenu,
    ChangePasswordDialog,
    UserSettings,
    OrganizationSelector,
  },
  computed: {
    ...mapGetters(['darkMode', 'user', 'isAdmin', 'isStaff', 'currentOrgId', 'hasAccess']),
  },
  methods: {
    bubbleEmit(val) {
      this.$emit('show-login', val);
    },
  },
};
</script>

