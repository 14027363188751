<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-form ref="loginForm" v-model="isValid" @submit="validate">
      <v-card v-if="!forgotPasswordView">
        <v-card-title class="mb-6"><v-img :src="darkMode ? '/assets/KGuideLogo-Dark.png' : '/assets/KGuideLogo.png'"></v-img> </v-card-title>
        <v-card-text class="pb-4">
          <v-row no-gutters>
            <v-col cols="12" class="px-2 mb-3">
              <v-text-field outlined v-model.trim="username" dense hide-details="auto" :rules="[rules.required]" label="Email" />
            </v-col>
            <v-col cols="12" class="px-2">
              <v-text-field
                outlined
                v-model="password"
                label="Kodeord"
                dense
                hide-details="auto"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col>
            <v-alert type="error" v-if="errorMessage">
              {{ errorMessage }}
            </v-alert>
            <r-btn block :disabled="!isValid" creation class="mr-4 mb-4" type="submit" :loading="isLoading"> Log ind </r-btn>
            <r-btn block color="gray" class="mt-4" @click="forgotPasswordView = true; errorMessage = null"> Glemt kodeord </r-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>Glemt kodeord</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="px-2">
              <v-text-field v-model.trim="username" :rules="[rules.required]" label="E-mail" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-expand-transition>
                <v-alert type="error" v-if="errorMessage">
                  {{ errorMessage }}
                </v-alert>
              </v-expand-transition>
              <v-expand-transition>
                <v-alert type="info" v-if="emailSent"> Der vil blive sendt en e-mail til dig, som indeholder et link til at ændre dit kodeord.</v-alert>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col>
            <r-btn block :disabled="!isValid" v-if="!emailSent" color="success" class="mr-4 mb-4" @click="sendEmail" :loading="isLoading">
              Nulstil kodeord
            </r-btn>
            <r-btn block soft class="mr-4" @click="loginView">Cancel</r-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'login',
  data: () => ({
    show: false,
    username: null,
    password: null,
    isValid: false,
    isLoading: false,
    showPassword: false,
    errorMessage: null,
    emailSent: false,
    forgotPasswordView: false,
    rules: {
      required: (value) => !!value || 'Du skal indtaste en værdi.',
    },
  }),
  computed: {
    ...mapGetters(['darkMode']),
  },
  methods: {
    validate(e) {
      if (this.$refs.loginForm.validate()) {
        this.isLoading = true;
        this.$store
          .dispatch('AUTHENTICATE', {
            username: this.username,
            password: this.password,
          })
          .then((user) => {
            this.show = false;
            this.isLoading = false;
            this.$emit('hide-login');
            this.$router.push({ name: 'Calendar', params: { UserId: user.id, UserName: user.name } });
          })
          .catch(() => {
            this.isLoading = false;
            this.errorMessage = 'Fejl - du kunne ikke logges ind i systemet';
          });
      }

      // We need to do this in order to not actually submit the form (which will reload the site)
      e.preventDefault();
    },
    loginView() {
      this.forgotPasswordView = false;
      this.password = '';
    },
    sendEmail() {
      this.isLoading = true;
      this.errorMessage = null;
      this.$store
        .dispatch('RESET_PASSWORD', this.username)
        .then((result) => {
          if (!result) {
            this.errorMessage = 'Unknown username';
          } else {
            this.emailSent = true;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.errorMessage = 'Unable to reset password';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDialog(val) {
      this.show = val;
    },
  },
};
</script>
