<template>
  <div>
    <v-list>
      <v-list-item class="my-0 py-0">
        <v-checkbox color="info" @click="changeTheme" v-model="$vuetify.theme.dark" inset label="Mørkt tema" hide-details="auto" class="mt-0 pt-0" />
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'UserSettings',
  data: () => ({}),
  computed: {
    ...mapGetters(['user', 'darkMode']),
  },
  methods: {
    changeTheme() {
      this.$store.dispatch('SET_DARKMODE', !this.darkMode);
    },
  },
};
</script>

<style>
</style>