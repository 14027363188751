<template>
  <v-dialog persistent v-bind="$attrs" v-model="showAlert" width="650">
    <v-card>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex">
          <v-card-title v-if="title">{{ title }}</v-card-title>
        </v-col>
        <v-col cols="12">
          <v-card-subtitle class="body-1" v-if="body">{{ body }}</v-card-subtitle>
          <v-card-subtitle class="body-1" v-if="studentName">{{ studentName }}</v-card-subtitle>
          <v-card-subtitle class="body-1" v-if="type">{{ type }}</v-card-subtitle>
        </v-col>
        <v-row no-gutters class="justify-center pa-2">
          <v-col cols="3" class="px-2 mr-auto">
            <r-btn block @click="returnCancel()" v-if="cancelBtn !== ''">Annuller</r-btn>
          </v-col>
          <v-col cols="3" class="px-2">
            <r-btn block @click="returnFalse()" v-if="cancelBtn !== ''">
              {{ cancelBtn }}
            </r-btn>
            <r-btn v-else block @click="returnFalse()">Annuller</r-btn>
          </v-col>
          <v-col cols="3" class="px-2">
            <r-btn delete block @click="returnTrue()">
              {{ okBtn }}
            </r-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import RBtn from '../RBtn.vue';
export default {
  components: { RBtn },
  name: 'ConfirmationDialog',
  props: {},
  data: () => ({
    title: '',
    body: '',
    cancelBtn: '',
    okBtn: '',
    studentName: '',
    type: '',

    showAlert: false,

    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    async showDialog(valueObject) {
      this.title = valueObject.title ?? '';
      this.body = valueObject.body ?? '';
      this.cancelBtn = valueObject.cancelBtn ?? '';
      this.okBtn = valueObject.okBtn ?? '';
      this.studentName = valueObject.studentName ?? '';
      this.type = valueObject.type ?? '';

      this.showAlert = true;

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    hideDialog() {
      this.showAlert = false;
    },
    returnCancel() {
      this.rejectPromise({ Error: 'Cancel' });
    },
    returnFalse() {
      this.resolvePromise(false);
    },
    returnTrue() {
      this.resolvePromise(true);
    },
  },
};
</script>

<style>
</style>